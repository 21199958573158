body {
  font-family: "Kanit", sans-serif;
  --bs-bg-opacity: 1;
  background-color: rgb(
    14,
    14,
    14
  ); /* overriding static style attribute for body tag */
}
h2,
.card-title,
.card-text {
  color: #e0e0e0;
}
.nav-item,
.nav-link {
  font-weight: 300;
}

a.item-card {
  text-decoration: none;
}

a.card-text {
  text-decoration: none;
}

a.item-card:hover h5 {
  text-decoration: underline;
}

a.card-text:hover {
  text-decoration: underline;
}

.cover {
  object-fit: cover;
}

.bg-darker {
  background-color: #121212 !important;
}

.card-header-tabs .nav-link.active {
  border-bottom-color: #00000000 !important;
}
